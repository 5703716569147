const color = {
  methods: {
    color(status) {
      switch (status) {
        case "Error":
          return "#E15454";
        case "Rechazado":
          return "#E15454";
        case "Completado":
          return "#07ba56";
        case "Publicado":
          return "#07ba56";
        case "Aprobado":
          return "#07ba56";
        case "En proceso":
          return "#F9CB57";
        case "Pendiente":
          return "#466BE3";
        case "Borrador":
          return "#466BE3";
        case "Anulado":
          return "#f9a095";
        case "Notificaciones":
          return "#F9CB57";
        default:
          return "#000";
      }
    },
  },
};
export default color;
